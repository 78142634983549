import React, {  useEffect, useState } from 'react'
import { Link } from 'gatsby'

import logo from '../img/logo.svg'


const LogoAnimation = ({

  style,
  logoPosition,
}) => {


  return (
    <div className="home-logo-animated">
      <Link to="/" style={{ ...style }}>
            <img
              src={logo}
              alt="Atelier"
              style={{
                ...logoPosition,
                transition: 'all 0.3s ease-out',
              }}
            />
          </Link>


    </div>
  )
}

export default LogoAnimation
